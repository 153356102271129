import React from "react"
import { Link } from "gatsby"
import {Helmet} from "react-helmet";

import Layout from "../components/layout"
import ConfianEnNosotros from "../components/confian-en-nosotros"

import icoAuto from "../assets/old/img/cotizador/ico_auto.png"
import icoHogar from "../assets/old/img/cotizador/ico_hogar.png"
import icoTecno from "../assets/old/img/cotizador/ico_tecnologia.png"
import icoArt from "../assets/old/img/cotizador/ico_art.png"

export default () => (
  <Layout menuActivo="cotizar-seguros">
    <Helmet>
      <title>Cotiza con nosotros</title>
      <meta
          name="description"
          content="Cotiza con nosotros y obtén beneficios en tus nuevas pólizas."
      />
    </Helmet>

    <section className="container pb-2 pt-5" id="cases">
      <h2 className="h3 block-title text-center mb-5">
          Cotiza en Web del Seguro<small>Te ofrecemos un abanico de soluciones</small>
      </h2>
        <div className="row">
            <div className="col-lg-3 col-sm-6">
                <div className="icon-box text-center mx-auto">
                    <div className="">
                        <img
                            className="d-block mx-auto"
                            src={icoAuto}
                            style={{ padding: "0 0 30px 0" }}
                            alt="Cotizador Autos"
                        />
                    </div>
                    <h3 className="icon-box-title">Seguro para tu Auto</h3>
                    <p className="icon-box-text text-sm">Cotiza el seguro para tu auto y obtén hasta un 30% en tu nueva póliza.</p>
                    <Link className="icon-box-link" to="/">Cotizar!!<i className="fe-icon-arrow-right"></i></Link>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6">
                <div className="icon-box text-center mx-auto">
                    <div className="">
                        <img
                            className="d-block mx-auto"
                            src={icoHogar}
                            style={{ padding: "0 0 30px 0" }}
                            alt="Cotizador Hogar"
                        />
                    </div>
                    <h3 className="icon-box-title">Seguro para tu Hogar</h3>
                    <p className="icon-box-text text-sm">Cotiza el seguro para tu hobar y obtén hasta un 30% en tu nueva póliza.</p>
                    <Link className="icon-box-link" to="/seguros/hogar">Cotizar!!<i className="fe-icon-arrow-right"></i></Link>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6">
                <div className="icon-box text-center mx-auto">
                    <div className="">
                        <img
                            className="d-block mx-auto"
                            src={icoTecno}
                            style={{ padding: "0 0 30px 0" }}
                            alt="Cotizador Tegnología"
                        />
                    </div>
                    <h3 className="icon-box-title">Seguro para tus Equipos</h3>
                    <p className="icon-box-text text-sm">Cotiza el seguro de tu celular, tablet o pc y obtén hasta un 30% en tu nueva póliza.</p>
                    <Link className="icon-box-link" to="/seguros/tecnologia">Cotizar!!<i className="fe-icon-arrow-right"></i></Link>
                </div>
            </div>
            <div className="col-lg-3 col-sm-6">
                <div className="icon-box text-center mx-auto">
                    <div className="">
                        <img
                            className="d-block mx-auto"
                            src={icoArt}
                            style={{ padding: "0 0 30px 0" }}
                            alt="Cotizador ART"
                        />
                    </div>
                    <h3 className="icon-box-title">Seguro para tu Empresa</h3>
                    <p className="icon-box-text text-sm">Cotiza el seguro para empresa y obtén hasta un 30% en tu nueva póliza.</p>
                    <Link className="icon-box-link" to="/seguros/cotizar-art">Cotizar!!<i className="fe-icon-arrow-right"></i></Link>
                </div>
            </div>
        </div>
        <hr className="mt-5" />
    </section>


    {/* Aseguradoras */}
    <ConfianEnNosotros />




  </Layout>
)
